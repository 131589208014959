import * as React from "react";
import {ASSETS} from "../common/images";
import Technologies from "../common/technologies";

const WebDevelopmentTechnologyCompetence = () => {
    return (
        <section className="Technology-Competence-sec gb-md-pad-ver">
            <div className="container">
                <div className="Technology-Competenc-heading text-center">
                    <h2>Our <span> Web Development Technology </span> Capabilities</h2>
                    <p>Get ready to unlock the latest business technologies! GeekyBones delivers the best solution by leveraging the latest technologies. We have been serving different businesses across several industries and specializations. Our wide array of innovative solutions across multiple domains helps in dominating the marketplace while maximizing productivity.</p>
                      <p>Join our hands and explore our technologies.
</p>
                </div>
                <Technologies />
            </div>
        </section>
    )
}
export default WebDevelopmentTechnologyCompetence;
